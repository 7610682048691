var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',[(_vm.hasVisualizations)?_c('v-list-item',{directives:[{name:"ripple",rawName:"v-ripple"}],class:['study-list-item', { hidden: !_vm.canBeSeen }],on:{"click":_vm.goToStudy}},[_c('v-list-item-avatar',{attrs:{"size":"26"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.getAvatarColor(_vm.study.product)}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.getAvatarIcon(_vm.study.product))+" ")])]}}],null,false,1875516596)},[_c('span',[_vm._v(_vm._s(_vm._f("formatProduct")(_vm.study.product)))])])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.study.name))]),_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(_vm._s(_vm.study.description || _vm.defaultDescription))]),_c('v-list-item-subtitle',{staticClass:"font-italic font-weight-light"},[_vm._v("Created: "+_vm._s(_vm._f("formatDate")(_vm.study.created))+" ")])],1),(_vm.study.status === 'SUCCEEDED')?[_c('v-list-item-action',[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-chevron-right")])],1)]:_c('v-list-item-action',[_c('v-chip',{attrs:{"color":_vm.statusColor}},[_vm._v(_vm._s(_vm.study.status))])],1)],2):_c('v-list-item',{class:['study-list-item', { hidden: !_vm.canBeSeen }]},[_c('v-list-item-avatar',{attrs:{"size":"26"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.getAvatarColor(_vm.study.product)}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.getAvatarIcon(_vm.study.product))+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("formatProduct")(_vm.study.product)))])])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.study.name))]),_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(_vm._s(_vm.study.description || _vm.defaultDescription))]),_c('v-list-item-subtitle',{staticClass:"font-italic font-weight-light"},[_vm._v("Created: "+_vm._s(_vm._f("formatDate")(_vm.study.created))+" ")])],1),(_vm.study.status === 'SUCCEEDED')?[_c('v-list-item-action',[_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.exportOrder}},[_c('span',[_vm._v("Download")])])],1),(['tla'].includes(_vm.study.product))?_c('v-list-item-action',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"study-item-menu",attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1382795901)},[_c('v-list',{attrs:{"dense":""}},[_c('add-poi-dialog',{attrs:{"study":_vm.study}},[_c('v-list-item-title',[_vm._v("Add POIs")])],1),_c('v-divider'),_c('rename-study-dialog',{attrs:{"study":_vm.study}},[_c('v-list-item-title',[_vm._v("Rename")])],1)],1)],1)],1):_c('v-list-item-action',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"study-item-menu",attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1382795901)},[_c('v-list',{attrs:{"dense":""}},[_c('rename-study-dialog',{attrs:{"study":_vm.study}},[_c('v-list-item-title',[_vm._v("Rename")])],1)],1)],1)],1)]:_c('v-list-item-action',[_c('v-chip',{attrs:{"color":_vm.statusColor}},[_vm._v(_vm._s(_vm.study.status))])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }