<template>
  <v-hover>
    <v-list-item :class="['study-list-item', { hidden: !canBeSeen }]" @click="goToStudy" v-ripple
      v-if="hasVisualizations">
      <v-list-item-avatar size="26">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon :color="getAvatarColor(study.product)" v-bind="attrs" v-on="on">{{ getAvatarIcon(study.product) }}
            </v-icon>
          </template>
          <span>{{ study.product | formatProduct }}</span>
        </v-tooltip>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ study.name }}</v-list-item-title>
        <v-list-item-subtitle class="caption">{{ study.description || defaultDescription }}</v-list-item-subtitle>
        <v-list-item-subtitle class="font-italic font-weight-light">Created: {{ study.created | formatDate }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <template v-if="study.status === 'SUCCEEDED'">
        <v-list-item-action>
          <v-icon large>mdi-chevron-right</v-icon>
        </v-list-item-action>
      </template>
      <v-list-item-action v-else>
        <v-chip :color="statusColor">{{ study.status }}</v-chip>
      </v-list-item-action>
    </v-list-item>
    <v-list-item :class="['study-list-item', { hidden: !canBeSeen }]" v-else>
      <v-list-item-avatar size="26">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon :color="getAvatarColor(study.product)" v-bind="attrs" v-on="on">{{ getAvatarIcon(study.product) }}
            </v-icon>
          </template>
          <span>{{ study.product | formatProduct }}</span>
        </v-tooltip>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ study.name }}</v-list-item-title>
        <v-list-item-subtitle class="caption">{{ study.description || defaultDescription }}</v-list-item-subtitle>
        <v-list-item-subtitle class="font-italic font-weight-light">Created: {{ study.created | formatDate }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <template v-if="study.status === 'SUCCEEDED'">
        <v-list-item-action>
          <v-btn outlined @click="exportOrder">
            <span>Download</span>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action v-if="['tla'].includes(study.product)">
          <v-menu>
            <template v-slot:activator="{ on }">
              <v-btn class="study-item-menu" icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <add-poi-dialog :study="study">
                <v-list-item-title>Add POIs</v-list-item-title>
              </add-poi-dialog>
              <v-divider />
              <rename-study-dialog :study="study">
                <v-list-item-title>Rename</v-list-item-title>
              </rename-study-dialog>
            </v-list>
          </v-menu>
        </v-list-item-action>
        <v-list-item-action v-else>
          <v-menu>
            <template v-slot:activator="{ on }">
              <v-btn class="study-item-menu" icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <rename-study-dialog :study="study">
                <v-list-item-title>Rename</v-list-item-title>
              </rename-study-dialog>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </template>
      <v-list-item-action v-else>
        <v-chip :color="statusColor">{{ study.status }}</v-chip>
      </v-list-item-action>
    </v-list-item>
  </v-hover>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import format from 'date-fns/format';
import minDate from 'date-fns/min';
import maxDate from 'date-fns/max';
import alert from '@/mixins/alert';
import studyIcon from '@/mixins/studyIcon';
import AddPoiDialog from '@/components/AddPoiDialog';
import RenameStudyDialog from '@/components/RenameStudyDialog';
import { unfurlNestedDates } from '@/lib/utils';

export default {
  mixins: [alert, studyIcon],

  components: {
    AddPoiDialog,
    RenameStudyDialog
  },

  props: {
    study: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      statusCheckHandler: null,
      products: {
        dla: 'Destination Location Analysis',
        tla: 'Target Location Analysis',
        ad: 'Activity Density',
        nwtm: 'Nationwide Trip Matrix',
        pp: 'Point Plot'
      }
    };
  },

  computed: {
    canBeSeen() {
      const organization = this.organizations.find((org) => org.id === this.study.organization);
      if (organization) {
        return organization.products[this.study.product].read;
      }
      return true;
    },

    statusColor() {
      if (this.study.status === 'RUNNING') {
        return 'primary';
      }
      if (this.study.status === 'SUCCEEDED') {
        return 'success';
      }
      if (this.study.status === 'FAILED') {
        return 'error';
      }
      return '';
    },

    hasVisualizations() {
      const { product, config: { visualize = true } } = this.study;
      return ['dla', 'tla', 'ad', 'nwtm', 'pp'].includes(product) && visualize;
    },

    defaultDescription() {
      const { product, config, studyarea, pois } = this.study;
      let description = '';
      const isMonthly = ['dla', 'nwtm'].includes(product);
      const interval = isMonthly ? 'month' : 'day';
      const dateFormatString = isMonthly ? 'MMM yyyy' : 'MMM dd, yyyy';
      const dates = unfurlNestedDates(config.dates, { interval });
      const firstDate = format(minDate(dates), dateFormatString);
      const lastDate = format(maxDate(dates), dateFormatString);
      let dateString = firstDate;
      if (firstDate !== lastDate) {
        dateString = `${firstDate} - ${lastDate}`;
      }

      const numLocations = get(config, 'locations', get(config, 'counties', [])).length;
      switch (product) {
        case 'dla':
          if (!isEmpty(studyarea) && studyarea.name.length) {
            const { buffer, maxBuffer } = config;
            let bufferText = '';
            if (buffer > 0 && maxBuffer === 0) {
              bufferText = `${buffer} mile buffer`;
            } else if (buffer === 0 && maxBuffer > 0) {
              bufferText = `${maxBuffer}+ mile buffer`;
            } else if (buffer > 0 && maxBuffer > 0) {
              if (buffer < maxBuffer) {
                bufferText = `${buffer} - ${maxBuffer} mile buffer`;
              }
            }
            description = `${studyarea.name} | ${bufferText} | ${config.geolevels.map((l) => startCase(l)).join(', ')} | ${dateString}`;
          } else {
            description = 'No description';
          }
          break;

        case 'tla':
          /* eslint-disable */
          let bufferText = '';
          if (config.minBuffer > 0 || config.maxBuffer > 0) {
            const { minBuffer, maxBuffer } = config;
            if (minBuffer > 0 && maxBuffer === 0) {
              bufferText = ` | ${minBuffer} mile buffer | `;
            } else if (minBuffer === 0 && maxBuffer > 0) {
              bufferText = ` | ${maxBuffer}+ mile buffer | `;
            } else if (minBuffer > 0 && maxBuffer > 0) {
              if (minBuffer < maxBuffer) {
                bufferText = ` | ${minBuffer} - ${maxBuffer} mile buffer | `;
              }
            }
          }
          description = `${pois.length} POI${pois.length === 1 ? '' : 's'} | ${dateString}${bufferText}`;
          break;

        case 'nwtm':
          description = `${numLocations} Count${numLocations === 1 ? 'y' : 'ies'} | ${dateString}`;
          break;

        case 'ad': case 'pp':
          description = `${studyarea.name} | ${dateString}`;
          break;

        default:
          description = 'No description';
          break;
      }

      return description;
    },

    ...mapState(['organizations'])
  },


  async mounted() {
    if (!['SUCCEEDED', 'FAILED', 'ARCHIVED'].includes(this.study.status)) {
      // start polling for order status
      this.statusCheckHandler = setInterval(this.refreshStatus, 5000);
    }
  },

  beforeDestroy() {
    if (this.statusCheckHandler) {
      clearInterval(this.statusCheckHandler);
    }
  },

  methods: {
    goToStudy() {
      if (this.study.status === 'SUCCEEDED') {
        if (this.hasVisualizations) {
          this.$router.push({ name: 'study', params: { id: this.study.id, study: this.study } });
        } else {
          this.exportOrder();
        }
      }
    },

    async refreshStatus() {
      try {
        const { data: status } = await this.$services.orders.getStatus(this.study.id);
        this.setOrderProperty({
          id: this.study.id,
          key: 'status',
          value: status
        });
        if (['SUCCEEDED', 'FAILED', 'ARCHIVED'].includes(this.study.status)) {
          clearInterval(this.statusCheckHandler);
        }
      } catch (error) {
        this.alertError(error);
      }
    },

    async exportOrder() {
      try {
        const { id, downloadUrl } = this.study;
        if (downloadUrl) {
          window.location = downloadUrl;
        } else {
          this.exporting = true;
          const { data } = await this.$services.orders.export(id);
          window.location = data;
          this.study.downloadUrl = data;
          this.setOrderProperty({ id, key: 'downloadUrl', value: data });
        }
      } catch (error) {
        this.alertError(error);
      } finally {
        this.exporting = false;
      }
    },

    ...mapActions(['setOrderProperty'])
  },

  filters: {
    formatDate(date) {
      if (date) {
        return format(date, 'MMM d, yyyy');
      }
      return date;
    },

    formatProduct(value) {
      const products = {
        dla: 'Destination Location Analysis',
        tla: 'Target Location Analysis',
        ad: 'Activity Density',
        nwtm: 'Nationwide Trip Matrix'
      };
      return products[value.toLowerCase()];
    }
  }
};
</script>

<style lang="scss">
.study-list-item {
  &.hidden {
    background: #F8F8F8;

    &::after {
      content: "\F06D1";
      color: #CDCDCD !important;
      display: inline-block;
      font: normal normal normal 32px/1 "Material Design Icons";
      font-size: 32px;
      text-rendering: auto;
      line-height: inherit;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-24px, -24px);
      -webkit-font-smoothing: antialiased;
    }
  }
}
</style>
