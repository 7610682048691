export default {
  methods: {
    getAvatarColor(product) {
      let color;
      if (product === 'dla') {
        color = '#00A237';
      } else if (product === 'tla') {
        color = '#DE7200';
      } else if (product === 'ad') {
        color = '#FFB606';
      } else if (product === 'nwtm') {
        color = '#02698B';
      } else if (product === 'pp') {
        color = '#27233A';
      } else {
        color = '#CDCDCD';
      }
      return color;
    },

    getAvatarIcon(product) {
      let icon;
      if (product === 'dla') {
        icon = 'mdi-map-marker-circle';
      } else if (product === 'tla') {
        icon = 'mdi-map-marker-outline';
      } else if (product === 'ad') {
        icon = 'mdi-grid';
      } else if (product === 'nwtm') {
        icon = 'mdi-data-matrix';
      } else if (product === 'pp') {
        icon = 'mdi-scatter-plot';
      } else {
        icon = 'mdi-text-box-outline';
      }
      return icon;
    }
  }
};
