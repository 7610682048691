<template>
  <v-dialog v-model="show" :fullscreen="isSmall" width="800" persistent>
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <slot></slot>
      </v-list-item>
    </template>
    <v-card flat :tile="isSmall">
      <v-card-title>
        <div class="headline">Rename Study</div>
      </v-card-title>
      <v-card-text>
        <v-text-field label="Study Name" v-model="name" prepend-icon="mdi-text-box-outline" hide-details />
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" text large @click="cancel">Cancel</v-btn>
        <v-spacer />
        <v-btn color="primary" depressed large :loading="pending" @click="rename" :disabled="!canRename">Rename</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import get from 'lodash/get';
import { mapActions } from 'vuex';
import alert from '@/mixins/alert';

export default {
  mixins: [alert],

  props: {
    study: {
      type: Object,
      default: () => { }
    }
  },

  data() {
    return {
      show: false,
      pending: false,
      name: ''
    };
  },

  watch: {
    show() {
      if (this.show) {
        this.name = this.study.name;
      } else {
        this.name = '';
      }
    }
  },

  computed: {
    isAuthenticated: {
      cache: false,
      get() {
        return this.$auth.isAuthenticated();
      }
    },

    studyOrganizationId() {
      return get(this.study, 'organization', '');
    },

    isSmall() {
      return this.$vuetify.breakpoint.xsOnly;
    },

    canRename() {
      return Boolean(this.isAuthenticated && this.name.length);
    }
  },

  methods: {
    async rename() {
      this.pending = true;
      try {
        const { id, name } = this.study;
        await this.$services.orders.update(id, { name: this.name });
        this.alertSuccess(`${name} renamed to ${this.name}`);
        this.setOrderProperty({ id, key: 'name', value: this.name });
        this.cancel();
      } catch (error) {
        this.alertError(error, false);
      } finally {
        this.pending = false;
      }
    },

    reset() {
      this.name = '';
    },

    cancel() {
      this.reset();
      this.show = false;
    },

    ...mapActions(['setOrderProperty'])
  }
};
</script>

<style lang="scss">
.small-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
